<template>
  <v-chart
    :style="{ height: $defined.HEIGHT - 195 + 'px' }"
    class="chart"
    @click="clickOnChart"
    :option="option"
  />
</template>

<script>
import "../../public/js/juli";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  TimelineComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import {
  ref,
  defineComponent,
  reactive,
  toRefs,
  watch,
  getCurrentInstance,
} from "vue";
use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  TimelineComponent,
]);

export default defineComponent({
  name: "chart",
  components: {
    VChart,
  },
  emits: ["clickOnChart"],
  provide: {
    [THEME_KEY]: "juli",
  },
  props: {
    columns: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: () => "",
    },
  },
  setup: (props, { emit }) => {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      option: {
        title: {
          text: props.columns.title,
          x: "center",
          y: "20px",
        },
        legend: {
          right: "center",
          bottom: "20px",
          data: props.columns.legend,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}",
        },
        grid: {
          left: "20px",
          right: "20px",
          bottom: "50px",
          containLabel: true,
        },
        xAxis: {
          type: props.columns.xData ? "category" : "value",
          data: props.columns.xData,
          axisLabel: {
            rotate:
              props.columns.xData && props.columns.xData.length > 10 ? 40 : 0,
          },
        },
        yAxis: {
          type: props.columns.yData ? "category" : "value",
          data: props.columns.yData,
        },
        series: [],
      },
      mulOption: {
        baseOption: {
          timeline: {
            axisType: "category",
            autoPlay: false,
            playInterval: 1000,
            data: props.columns.timeline,
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}",
          },
          title: {
            x: "center",
            y: proxy.$defined.WIDTH <= 768 ? "0" : "20px",
          },
          grid: {
            left: "20px",
            right: "20px",
            bottom: "60px",
            top: "80px",
            containLabel: true,
          },
          legend: {
            right: 0,
            top: proxy.$defined.WIDTH <= 768 ? "30px" : "20px",
            data: props.columns.legend,
          },
          calculable: true,
          xAxis: [
            {
              type: "category",
              data: props.columns.xData,
              splitLine: { show: false },
            },
          ],
          yAxis: [{ type: "value" }],
          series: props.columns.series,
        },
        options: props.columns.mulData,
      },
    });
    const option = ref(state[props.name]);
    const initData = () => {
      if (props.name === "option") {
        if (props.columns.type === "pie") {
          state[props.name].xAxis = null;
          state[props.name].yAxis = null;
          state[props.name].title.text = props.columns.title;
        }
        props.columns.series.map((item) => {
          item.type = props.columns.type;
          state[props.name].series.push(item);
        });
      }
      if (props.columns.mulData) {
        option.value.options = JSON.parse(
          JSON.stringify(props.columns.mulData)
        );
      }
    };

    initData();

    const clickOnChart = (row) => {
      emit("clickOnChart", row);
    };
    watch(
      () => props.columns,
      () => {
        //通过一个函数返回要监听的属性
        initData();
      },
      {
        deep: true,
      }
    );

    return { ...toRefs(state), option, clickOnChart };
  },
});
</script>

<style scoped>
.chart {
  /* height: 700px; */
}
</style>
