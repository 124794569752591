/* eslint-disable */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory);
  } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
}(this, function (exports, echarts) {
  var log = function (msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log('ECharts is not Loaded');
    return;
  }
  echarts.registerTheme('juli', {
    "color": [
      "#f7b070",
      "#278ef4",
      "#a763ef",
      "#fa583e",
      "#89c075",
      "#fce38c",
      "#d87a80",
      "#2ec7c9",
      "#5ab1ef",
      "#c12e34",
      "#b6a2de"
    ],
    "backgroundColor": "#f7f7f7",
    "textStyle": {},
    "title": {
      "textStyle": {
        "color": "#454545"
      },
      "subtextStyle": {
        "color": "#884488"
      }
    },
    "line": {
      "itemStyle": {
        "normal": {
          "borderWidth": "2"
        }
      },
      "lineStyle": {
        "normal": {
          "width": "2"
        }
      },
      "symbolSize": "6",
      "symbol": "emptyCircle",
      "smooth": true
    },
    "radar": {
      "itemStyle": {
        "normal": {
          "borderWidth": "2"
        }
      },
      "lineStyle": {
        "normal": {
          "width": "2"
        }
      },
      "symbolSize": "6",
      "symbol": "emptyCircle",
      "smooth": true
    },
    "bar": {
      "itemStyle": {
        "normal": {
          "barBorderWidth": "0",
          "barBorderColor": "#ccc"
        },
        "emphasis": {
          "barBorderWidth": "0",
          "barBorderColor": "#ccc"
        }
      }
    },
    "pie": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "scatter": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "boxplot": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "parallel": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "sankey": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "funnel": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "gauge": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        },
        "emphasis": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      }
    },
    "candlestick": {
      "itemStyle": {
        "normal": {
          "color": "#ae72ef",
          "color0": "transparent",
          "borderColor": "#fa583e",
          "borderColor0": "#89c075",
          "borderWidth": "2"
        }
      }
    },
    "graph": {
      "itemStyle": {
        "normal": {
          "borderWidth": "0",
          "borderColor": "#ccc"
        }
      },
      "lineStyle": {
        "normal": {
          "width": 1,
          "color": "#aaaaaa"
        }
      },
      "symbolSize": "6",
      "symbol": "emptyCircle",
      "smooth": true,
      "color": [
        "#f7b070",
        "#278ef4",
        "#a763ef",
        "#fa583e",
        "#89c075",
        "#fce38c",
        "#d87a80",
        "#2ec7c9",
        "#5ab1ef",
        "#c12e34",
        "#b6a2de"
      ],
      "label": {
        "normal": {
          "textStyle": {
            "color": "#ffffff"
          }
        }
      }
    },
    "map": {
      "itemStyle": {
        "normal": {
          "areaColor": "#f3f3f3",
          "borderColor": "#999999",
          "borderWidth": 0.5
        },
        "emphasis": {
          "areaColor": "rgba(255,178,72,1)",
          "borderColor": "#eb8146",
          "borderWidth": 1
        }
      },
      "label": {
        "normal": {
          "textStyle": {
            "color": "#131413"
          }
        },
        "emphasis": {
          "textStyle": {
            "color": "rgb(245,230,233)"
          }
        }
      }
    },
    "geo": {
      "itemStyle": {
        "normal": {
          "areaColor": "#f3f3f3",
          "borderColor": "#999999",
          "borderWidth": 0.5
        },
        "emphasis": {
          "areaColor": "rgba(255,178,72,1)",
          "borderColor": "#eb8146",
          "borderWidth": 1
        }
      },
      "label": {
        "normal": {
          "textStyle": {
            "color": "#131413"
          }
        },
        "emphasis": {
          "textStyle": {
            "color": "rgb(245,230,233)"
          }
        }
      }
    },
    "categoryAxis": {
      "axisLine": {
        "show": true,
        "lineStyle": {
          "color": "#aaaaaa"
        }
      },
      "axisTick": {
        "show": false,
        "lineStyle": {
          "color": "#333"
        }
      },
      "axisLabel": {
        "show": true,
        "textStyle": {
          "color": "#333"
        }
      },
      "splitLine": {
        "show": false,
        "lineStyle": {
          "color": [
            "#aaaaaa"
          ]
        }
      },
      "splitArea": {
        "show": false,
        "areaStyle": {
          "color": [
            "rgba(250,250,250,0.05)",
            "rgba(200,200,200,0.02)"
          ]
        }
      }
    },
    "valueAxis": {
      "axisLine": {
        "show": true,
        "lineStyle": {
          "color": "#aaaaaa"
        }
      },
      "axisTick": {
        "show": false,
        "lineStyle": {
          "color": "#333"
        }
      },
      "axisLabel": {
        "show": true,
        "textStyle": {
          "color": "#a2a2a2"
        }
      },
      "splitLine": {
        "show": true,
        "lineStyle": {
          "color": [
            "#aaaaaa"
          ]
        }
      },
      "splitArea": {
        "show": false,
        "areaStyle": {
          "color": [
            "rgba(250,250,250,0.05)",
            "rgba(200,200,200,0.02)"
          ]
        }
      }
    },
    "logAxis": {
      "axisLine": {
        "show": true,
        "lineStyle": {
          "color": "#aaaaaa"
        }
      },
      "axisTick": {
        "show": false,
        "lineStyle": {
          "color": "#333"
        }
      },
      "axisLabel": {
        "show": true,
        "textStyle": {
          "color": "#a2a2a2"
        }
      },
      "splitLine": {
        "show": true,
        "lineStyle": {
          "color": [
            "#aaaaaa"
          ]
        }
      },
      "splitArea": {
        "show": false,
        "areaStyle": {
          "color": [
            "rgba(250,250,250,0.05)",
            "rgba(200,200,200,0.02)"
          ]
        }
      }
    },
    "timeAxis": {
      "axisLine": {
        "show": true,
        "lineStyle": {
          "color": "#aaaaaa"
        }
      },
      "axisTick": {
        "show": false,
        "lineStyle": {
          "color": "#333"
        }
      },
      "axisLabel": {
        "show": true,
        "textStyle": {
          "color": "#a2a2a2"
        }
      },
      "splitLine": {
        "show": true,
        "lineStyle": {
          "color": [
            "#aaaaaa"
          ]
        }
      },
      "splitArea": {
        "show": false,
        "areaStyle": {
          "color": [
            "rgba(250,250,250,0.05)",
            "rgba(200,200,200,0.02)"
          ]
        }
      }
    },
    "toolbox": {
      "iconStyle": {
        "normal": {
          "borderColor": "#999999"
        },
        "emphasis": {
          "borderColor": "#666666"
        }
      }
    },
    "legend": {
      "textStyle": {
        "color": "#666666"
      }
    },
    "tooltip": {
      "axisPointer": {
        "lineStyle": {
          "color": "#cccccc",
          "width": 1
        },
        "crossStyle": {
          "color": "#cccccc",
          "width": 1
        }
      }
    },
    "timeline": {
      "lineStyle": {
        "color": "#2482f0",
        "width": "1"
      },
      "itemStyle": {
        "normal": {
          "color": "#2482f0",
          "borderWidth": 1
        },
        "emphasis": {
          "color": "#48a7ff"
        }
      },
      "controlStyle": {
        "normal": {
          "color": "#2482f0",
          "borderColor": "#2482f0",
          "borderWidth": 0.5
        },
        "emphasis": {
          "color": "#2482f0",
          "borderColor": "#2482f0",
          "borderWidth": 0.5
        }
      },
      "checkpointStyle": {
        "color": "#2482f0",
        "borderColor": "rgba(36,130,240,0.18)"
      },
      "label": {
        "normal": {
          "textStyle": {
            "color": "#2482f0"
          }
        },
        "emphasis": {
          "textStyle": {
            "color": "#2482f0"
          }
        }
      }
    },
    "visualMap": {
      "color": [
        "#268df1"
      ]
    },
    "dataZoom": {
      "backgroundColor": "rgba(255,255,255,0)",
      "dataBackgroundColor": "rgba(72,90,255,0.8)",
      "fillerColor": "rgba(72,134,255,0.62)",
      "handleColor": "#485aff",
      "handleSize": "100%",
      "textStyle": {
        "color": "#454545"
      }
    },
    "markPoint": {
      "label": {
        "normal": {
          "textStyle": {
            "color": "#ffffff"
          }
        },
        "emphasis": {
          "textStyle": {
            "color": "#ffffff"
          }
        }
      }
    }
  });
}));
/* eslint-disable no-new */